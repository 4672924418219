<template>
  <div style="display: grid; gap: 1.5rem">
    <FormLabel>
      <p>{{ $t('form.title') }}</p>
      <select id="title" v-model="forms.user.titleId">
        <option v-for="title in titles" :key="title.id" :value="title.id">
          {{ title.title }}
        </option>
      </select>
    </FormLabel>
    <FormLabel>
      <p>{{ $t('form.first-name') }}</p>
      <FormField
        v-model="forms.user.firstName"
        :pattern="REGEX.NAME"
        required
        @valid="forms.vset.user.firstName"
      />
    </FormLabel>
    <FormLabel>
      <p>{{ $t('form.last-name') }}</p>
      <FormField
        v-model="forms.user.lastName"
        :pattern="REGEX.NAME"
        required
        @valid="forms.vset.user.lastName"
      />
    </FormLabel>
    <FormLabel>
      <p>{{ $t('form.dob') }}</p>
      <FormField
        ref="dob_input_field"
        :value="displayDate"
        required
        @focus="e => $refs.datePicker.open(e)"
      />
    </FormLabel>
    <datetime
      ref="datePicker"
      v-model="forms.user.dateOfBirth"
      :max-datetime="dp.max"
      :min-datetime="dp.min"
      auto
      value-zone="UTC"
      zone="UTC"
    />
    <!-- <FormLabel>
      <p>{{ $t('form.email') }}</p>
      <FormField
        v-model="forms.user.email"
        type="email"
        required
        @valid="forms.vset.user.email"
      />
    </FormLabel> -->
    <FormLabel>
      <p>{{ $t('form.phone') }}</p>
      <div style="display: grid; grid-template-columns: 1fr 2fr; gap: 1rem">
        <div>
          <FormField
            id="call-code"
            v-model="callCode"
            name="call-code"
            autocomplete="off"
            list="call-code-list"
            :validator="
              value =>
                phoneCountries.some(country => country.callCode === value)
            "
            required
            @valid="forms.vset.user.phoneCountryId"
            @input="onCallCodeInput"
          />
        </div>
        <div>
          <FormField
            v-model="forms.user.phone"
            type="tel"
            pattern="\d*"
            minlength="5"
            name="telephone"
            required
            @valid="forms.vset.user.phone"
          />
        </div>
      </div>
    </FormLabel>
    <datalist id="call-code-list">
      <option
        v-for="country in phoneCountries"
        :key="country.id"
        :value="country.callCode"
      >
        {{ country.name }}
      </option>
    </datalist>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { REGEX } from '../../constants/REGEX'
import { createDatepicker } from '../../other/utils/datepickerUtil'
import FormField from './FormField.vue'
import FormLabel from './FormLabel.vue'

export default {
  name: 'UserTravelerForm',
  components: { FormLabel, FormField },
  props: {
    type: {
      type: String,
      required: true,
      validator: value => ['edit', 'create'].includes(value),
    },
  },
  created() {
    this.$watch(
      () => this.forms.user.dateOfBirth,
      value => this.forms.vset.user.dateOfBirth(!!value),
      { immediate: true },
    )
    Object.keys(this.forms.user).forEach(key => {
      if (key === 'phoneCountryId' || key === 'phone') return
      if (key === 'dateOfBirth')
        this.forms.user.dateOfBirth = this.dp.getValue(this.user.dateOfBirth)
      else this.forms.user[key] = this.user[key]
    })
    this.initPhone()
  },
  data() {
    return {
      dp: createDatepicker({ min: 100, max: 18, initial: 30 }),
      REGEX,
      callCode: '',
      forms: {
        user: {
          titleId: undefined,
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          phone: '',
          phoneCountryId: '',
          // email: '',
        },
      },
    }
  },
  watch: {
    'forms.vget.user.form': {
      immediate: true,
      handler(value) {
        this.$emit('valid', value)
      },
    },
    'forms.user.titleId'(value) {
      this.forms.vset.user.titleId(!!value)
    },
  },
  computed: {
    ...mapState('UserModule', ['user']),
    ...mapState('TitleModule', ['titles']),
    ...mapGetters('UserModule', ['userLocation']),
    ...mapGetters('CountryModule', ['phoneCountries']),
    displayDate: vm => vm.dp.formatValue(vm.forms.user.dateOfBirth),
  },
  methods: {
    ...mapActions('CountryModule', ['getCountries']),
    async initPhone() {
      if (!this.phoneCountries.length) await this.getCountries()
      const userPhone = this.user && this.user.phone && this.user.phoneCountryId
      const form = this.forms.user
      const countries = this.phoneCountries

      const country = userPhone
        ? countries.find(({ id }) => id === this.user.phoneCountryId)
        : countries.find(
            ({ callCode }) => callCode === 'USA +1' || callCode === 'US +1',
          )

      if (userPhone) {
        form.phone = this.user.phone
        this.forms.vset.user.phone(true)
      }
      if (country) {
        form.phoneCountryId = country.id
        this.forms.vset.user.phoneCountryId(true)
        this.callCode = country.callCode
      }
    },
    updateUser() {
      this.$emit('update-start')
      this.$store
        .dispatch('UserModule/updateUser', this.forms.user)
        .then(
          () => this.$emit('update-success'),
          () => this.$emit('update-error'),
        )
        .finally(() => this.$emit('update-end'))
    },
    onCallCodeInput(value) {
      const country = this.phoneCountries.find(
        ({ callCode }) => callCode === value,
      )
      if (!country) return
      this.forms.user.phoneCountryId = country.id
      window.event.target.blur()
    },
  },
}
</script>

<style scoped lang="scss"></style>
