<template>
  <div class="container-xs" style="display: grid; gap: 2rem">
    <AppBackHeader to="/search/summary">YOUR INFORMATION</AppBackHeader>
    <AppDataExclaimer />
    <UserForm
      ref="form"
      type="edit"
      @valid="value => (isValid = value)"
      @update-start="onUpdateStart"
      @update-success="onUpdateSuccess"
      @update-error="onUpdateError"
      @update-end="onUpdateEnd"
    />
    <XButton type="large" :disabled="!isValid" @click="$refs.form.updateUser()"
      >SAVE</XButton
    >
    <InfoModal
      ref="infoModal"
      title="Error"
      text="Something went wrong"
      cta="try again"
      @cta="$refs.infoModal.hide()"
    />
    <LoadingModalFullScreen
      ref="loadingModal"
      text="Please wait"
      non-closable
    />
  </div>
</template>

<script>
import AppBackHeader from '@/components-rf/AppBackHeader.vue'
import UserForm from '@/components-rf/forms/UserForm.vue'
import LoadingModalFullScreen from '../../../components-rf/modals/LoadingModalFullScreen.vue'
import InfoModal from '../../../components-rf/modals/InfoModal.vue'
import AppDataExclaimer from '../../../components-rf/AppDataExclaimer.vue'

export default {
  name: 'TripUserUpdate',
  components: {
    AppBackHeader,
    UserForm,
    InfoModal,
    LoadingModalFullScreen,
    AppDataExclaimer,
  },
  data() {
    return { isValid: false }
  },
  methods: {
    onUpdateStart() {
      this.$refs.loadingModal.show()
    },
    onUpdateSuccess() {
      this.$router.push('/search/summary')
    },
    onUpdateError() {
      this.$refs.infoModal.show()
    },
    onUpdateEnd() {
      this.$refs.loadingModal.hide()
    },
  },
}
</script>

<style scoped lang="scss"></style>
